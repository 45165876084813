import { Components, CSSInterpolation } from '@mui/material/styles';

const styleOverrides: CSSInterpolation = {
  root: ({ ownerState, theme }) => ({
    color: theme.palette.grey['900'],
    font: 'normal normal normal 1.25rem/1.5625rem Source Sans Pro',
    textDecoration: 'none',
    ...(ownerState.type === 'desktopNavItem' && {
      letterSpacing: 0
    }),
    ...(ownerState.type === 'mobileNavItem' && {
      marginBlock: '0.625rem'
    }),
    ...(ownerState.className === 'selected' && {
      color: theme.palette.primary.main,
      fontWeight: 600
    })
  })
};

export default { styleOverrides } as Components['MuiLink'];
