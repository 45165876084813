import { Components, CSSInterpolation } from '@mui/material/styles';

const styleOverrides: CSSInterpolation = {
  root: ({ ownerState, theme }) => ({
    padding: '0.875rem 2.625rem',
    border: `0.125rem solid ${theme.palette.primary.main}`,
    borderRadius: '0.375rem',
    '&:hover': {
      opacity: '0.8'
    },
    ...(ownerState.className?.includes('white') && {
      color: theme.palette.primary.contrastText,
      borderColor: theme.palette.primary.contrastText
    }),
    ...(ownerState.className?.includes('text') && {
      padding: 0,
      color: theme.palette.info.main,
      fontWeight: 600,
      border: `none`,
      borderBottom: `0.0625rem solid ${theme.palette.info.main}`,
      borderRadius: 0,
      letterSpacing: '0.0375rem'
    })
  })
};

export default { styleOverrides } as Components['MuiButton'];
