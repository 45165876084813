import { useTheme } from '@mui/material';
import NextNprogress from 'nextjs-progressbar';
import ErrorManager from 'features/core/ErrorManager';

const AppContent: React.FC = ({ children }) => {
  const theme = useTheme();

  return (
    <ErrorManager>
      <NextNprogress color={theme.palette.primary.main} />
      {children}
    </ErrorManager>
  );
};

export default AppContent;
