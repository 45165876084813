import { BreakpointsOptions } from '@mui/material';

const brealpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 690,
    md: 900,
    lg: 1200,
    xl: 1536
  }
};

export default brealpoints;
