import { Components, CSSInterpolation } from '@mui/material/styles';

const styleOverrides: CSSInterpolation = {
  root: ({ theme }) => ({
    padding: '1.5rem 0rem',
    boxSizing: 'border-box',
    color: theme.palette.grey[900],
    letterSpacing: '0rem',
    '&.Mui-selected': {
      fontWeight: 600,
      color: theme.palette.primary.main
    }
  })
};
export default { styleOverrides } as Components['MuiTab'];
