import palette from './palette';

const typography = {
  fontFamily: [
    'Source Sans Pro',
    'Noto Sans',
    'Helvetica',
    'Arial',
    'sans-serif'
  ].join(','),
  fontWeightLight: 200,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightBold: 700,
  useNextVariants: true,
  /* letter-spacing still needs to be configured for each */
  h1: {
    fontSize: '5rem', // 80px
    fontWeight: 700,
    lineHeight: 1.0625, // 85px
    letterSpacing: '-0.2rem',
    '@media screen and (max-width: 899px)': {
      fontSize: '2.875rem'
    }
  },
  h2: {
    fontSize: '5rem', // 80px
    fontWeight: 700,
    lineHeight: 1.0625, // 85px
    letterSpacing: '-0.2rem',
    '@media screen and (max-width: 899px)': {
      fontSize: '2.875rem'
    }
  },
  h3: {
    fontSize: '3.125rem', // 50px
    fontWeight: 700,
    lineHeight: 1.08, // 54px
    letterSpacing: '-0.01rem',
    color: palette.grey[900],
    '@media screen and (max-width: 899px)': {
      fontSize: '1.87rem'
    }
  },
  h4: {
    fontSize: '1.875rem', // 30px
    fontWeight: 700,
    lineHeight: 1.67, // 50px
    letterSpacing: '-0.04rem'
  },
  h5: {
    fontSize: '1.75rem', // 28px
    fontWeight: 700,
    lineHeight: 1.35, // 38px
    letterSpacing: 0
  },
  h6: {
    fontSize: '1.625rem', // 26px
    fontWeight: 400,
    lineHeight: 1.27, // 33px
    letterSpacing: 0
  },
  body1: {
    fontFamily: 'Noto Sans',
    fontSize: '1.5rem', // 24px
    lineHeight: 1.5, // 32px
    letterSpacing: 0,
    '@media screen and (max-width: 899px)': {
      fontSize: '1.25rem'
    }
  },
  body2: {
    fontFamily: 'Noto Sans',
    fontSize: '1.25rem', // 20px
    lineHeight: 1.4, // 28px
    fontWeight: 400,
    color: palette.grey[900],
    '@media screen and (max-width: 899px)': {
      fontSize: '1.25rem'
    }
  },
  link: {
    fontFamily: 'Source Sans Pro',
    // eslint-disable-next-line
    color: 'black',
    fontSize: '1.25rem', // 20px
    lineHeight: 1.4, // 28px
    fontWeight: 600,
    letterSpacing: 0.6
  },
  button: {
    fontSize: '1.25rem', // 20px
    fontWeight: 400,
    lineHeight: 1.25, // 25px
    letterSpacing: 0,
    textTransform: 'none' as const
  }
};

export default typography;
