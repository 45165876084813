import { GlobalStyles } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import theme from './index';

const WithTheme: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles styles={{}} />
      {children}
    </ThemeProvider>
  );
};

export default WithTheme;
