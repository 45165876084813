export const getApiBaseUrl = (): string =>
  process.env.NEXT_PUBLIC_API_BASE_URL!;

export const isAppStageProduction = (): boolean =>
  process.env.NEXT_PUBLIC_REACT_APP_NODE_ENV === 'production';

export const getGTMId = (): string => process.env.NEXT_PUBLIC_GTM_ID;

export const getSentryDSN = (): string => process.env.NEXT_PUBLIC_SENTRY_DSN;

export const getSentryRelease = (): string =>
  process.env.NEXT_PUBLIC_SENTRY_RELEASE || 'dev-local';

export const getSentryEnvironment = (): string =>
  process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT || 'development';

export const getStaticAPIToken = (): string =>
  process.env.NEXT_PUBLIC_STATIC_API_TOKEN;
