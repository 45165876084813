// libs
import { KeyValue } from 'types/general';
import { EVENTS } from './constants';
import { RudderstackAnalytics } from './rudderstack';
import { SegmentAnalytics } from './segment';
import { checkForMobileDevice } from 'utils/browserUtils';
// import './snackbar.css'

declare global {
  interface Window {
    analytics: any;
    rudderanalytics: any;
    dataLayer: { [key: string]: string | number | boolean | object }[];
    confirmationResult: any;
    recaptchaVerifier: any;
    clevertap: any;
    bowser: any;
    DesktopSubscriptionWebViewHandler: {
      getHost: () => void;
      getCredentials: () => void;
      paymentSuccessCb: () => void;
      paymentFailureCb: () => void;
      paymentPendingCb: () => void;
    };
  }
}
let commonEventProperties: any = {
  kb_device: checkForMobileDevice() ? 'mweb' : 'Web'
};
export const setCommonEventProperties = (props: KeyValue) => {
  commonEventProperties = {
    ...commonEventProperties,
    ...props
  };
};

const rudderAnalytics = new RudderstackAnalytics();
const segmentAnalytics = new SegmentAnalytics();

export const initializeAnalytics = (isMobile: boolean) => {
  segmentAnalytics?.initialize();
  rudderAnalytics?.initialize();
};

let localUserId: string | undefined;
export const setUserInAnalytics = (userId: string) => {
  // if we have already set userId and it is same as local
  // avoid refiring GTM event
  if (localUserId === userId) {
    return;
  }

  localUserId = userId;
  //set userId
  segmentAnalytics?.identifyUser(userId);
  rudderAnalytics?.identifyUser(userId);

  //set userId on gtm
  // window.dataLayer.push({ event: 'SET_USER', userId })
};

export const unsetUserInAnalytics = () => {
  const userId = '';
  //set userId
  segmentAnalytics?.identifyUser(userId);
  rudderAnalytics?.identifyUser(userId);

  //set userId on gtm
  window.dataLayer.push({ event: 'SET_USER', userId });
};

export const sendEvent = (
  event: EVENTS,
  data?: {
    [key: string]: string | number | boolean | object | null | undefined;
  }
) => {
  const payload = {
    ...commonEventProperties,
    ...data
  };
  segmentAnalytics?.trackEvent(event, payload);
  rudderAnalytics?.trackEvent(event, payload);
};
