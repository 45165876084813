import { useTheme } from '@mui/material';
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { getWindow } from 'utils/browserUtils';

// import { isAppStageProduction } from 'utils/settings';

interface HeadProps {
  title?: string;
  addOg?: boolean;
  addFull?: boolean;
  imageUrl?: string;
  keywords?: string;
  description?: string;
  isProd?: boolean;
}

const Head: React.FC<HeadProps> = ({
  title,
  imageUrl,
  keywords,
  description,
  isProd = false,
  addOg = false,
  addFull = false
}) => {
  const theme = useTheme();

  const { basePath } = useRouter();

  const href = getWindow()?.location.href;

  return (
    <NextHead>
      <title>{title}</title>
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width"
      />
      {addFull && (
        <>
          {isProd ? (
            <meta
              name="robots"
              content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
            />
          ) : (
            <meta name="robots" content="noindex, nofollow" />
          )}
          <link rel="canonical" href={href} />
          <meta name="keywords" content={keywords} />
          <meta name="description" content={description} />
          <link rel="alternate" hrefLang="en" href={href} />
          <link
            rel="shortcut icon"
            type="image/x-icon"
            href={`${basePath}/favicon.ico`}
          />
          <link
            rel="icon"
            type="image/x-icon"
            href={`${basePath}/favicon.ico`}
          />
          {/* PWA primary color */}
          <meta name="theme-color" content={theme.palette.primary.main} />
          {addOg && !!imageUrl && (
            <meta property="og:image" content={imageUrl} />
          )}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:title"
            content="Khatabook - Manage your Business &amp; Personal ledgers on your phone"
          />
          <meta
            property="og:description"
            content="Digital India&#x27;s Digital Khata! Download Khatabook now &amp; take charge of all your transactions, use Khatabook and grow your business!"
          />
          <meta property="og:url" content="https://khatabook.com/" />
          <meta property="og:site_name" content="Khatabook" />
          <meta
            property="article:modified_time"
            content="2020-02-11T10:18:48+00:00"
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="google-site-verification"
            content="49ABLSSrCoI8zn16IadDKllK-FIZY7JHvobGrH5uP9E"
          />
          <meta
            name="facebook-domain-verification"
            content="2t8q2we4jglcj32zkcvw41293t9g1x"
          />
        </>
      )}
    </NextHead>
  );
};

export default Head;

export type { HeadProps };
