import { json } from 'stream/consumers';

export class SegmentAnalytics {
  private readonly isEnabled: boolean = false;
  private readonly key: string | undefined;

  constructor() {
    //disabling segment events for kb-website
    this.isEnabled = false;
    this.key = process.env.NEXT_PUBLIC_SEGMENT_KEY;
  }

  private hasLoaded(): boolean {
    return !!window.analytics;
  }

  initialize() {
    if (!this.isEnabled) {
      console.log('Segment not enabled, skipping initialisation');
      return;
    }

    if (!this.key) {
      console.error('Key missing for rudderstack, skipping initialisation');
      return;
    }

    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked) {
        window.console &&
          console.warn &&
          console.warn('Segment snippet already included, returning.');
        return;
      } else {
        analytics.invoked = !0;
        analytics.methods = [
          'trackSubmit',
          'trackClick',
          'trackLink',
          'trackForm',
          'pageview',
          'identify',
          'reset',
          'group',
          'track',
          'ready',
          'alias',
          'debug',
          'page',
          'once',
          'off',
          'on',
          'addSourceMiddleware',
          'addIntegrationMiddleware',
          'setAnonymousId',
          'addDestinationMiddleware'
        ];
        analytics.factory = function (e: any) {
          return function (...args: any) {
            args.unshift(e);
            analytics.push(args);
            return analytics;
          };
        };
        for (let e = 0; e < analytics.methods.length; e++) {
          const key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key: string, e: any) {
          const t = document.createElement('script');
          t.type = 'text/javascript';
          t.async = !0;
          t.src =
            'https://cdn.segment.com/analytics.js/v1/' +
            key +
            '/analytics.min.js';
          const head = document.getElementsByTagName('head')[0];
          head.appendChild(t);
          analytics._loadOptions = e;
        };
        analytics.SNIPPET_VERSION = '4.13.1';
        analytics.load(this.key);
      }
  }

  identifyUser(userId: string) {
    if (!this.isEnabled || !this.hasLoaded()) {
      return;
    }
    window.analytics.identify(userId);
  }

  trackEvent(event: string, data: any) {
    if (!this.isEnabled || !this.hasLoaded()) {
      return;
    }
    window.analytics.track(event, data);
  }
}
