import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface Props {
  message: string;
  retryBtnText?: string;
  onRetry?: () => void;
  errorExtraAction?: () => void;
  errorExtraActionText?: string;
}

export const ActionsStyled = styled(Box)(({ theme }) => ({
  '& > *': {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: 0
    }
  }
}));

const ErrorSection: React.FC<Props> = ({
  message,
  retryBtnText,
  onRetry,
  errorExtraAction,
  errorExtraActionText
}) => {
  return (
    <Box textAlign="center" color="error.main">
      <Typography color="error">{message}</Typography>
      {(onRetry || errorExtraAction) && (
        <>
          <ActionsStyled
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {onRetry && (
              <Button
                variant="outlined"
                color="inherit"
                onClick={onRetry}
                size="small"
              >
                {retryBtnText}
              </Button>
            )}
            {errorExtraAction && (
              <Button
                variant="outlined"
                onClick={errorExtraAction}
                size="small"
              >
                {errorExtraActionText}
              </Button>
            )}
          </ActionsStyled>
        </>
      )}
    </Box>
  );
};

ErrorSection.defaultProps = {
  retryBtnText: null,
  onRetry: null
};

export default ErrorSection;
