export const IOS_APP_URL =
  'https://apps.apple.com/us/app/khata-book-udhar-bahi-khata/id1488204139';
export const ANDROID_APP_URL =
  'https://play.google.com/store/apps/details?id=com.vaibhavkalpe.android.khatabook&hl=en_IN';

export const CHAT_WITH_US_NUMBER = '+919606800800';
export const LEARN_HOW_TO_USE =
  'https://www.youtube.com/channel/UCO5RdGh7LkftrfsGgYP5E7w';

export const X_KB_APP_VERSION = 'Infinity';

export const TERMS_OF_USE_URL = 'https://khatabook.com/terms';

export const PRIVACY_POLICY_URL = 'https://khatabook.com/privacy';

export const TRIAL_PLAN_DAYS = 7;

export const ABOUT_US_URL = 'https://khatabook.com/about/';

export const FAQ_IFRAME_URL = 'https://khatabook.com/web-help/en';
