import { Components, CSSInterpolation } from '@mui/material/styles';

const styleOverrides: CSSInterpolation = {
  indicator: ({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    height: 3
  }),
  flexContainer: () => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%'
  })
};
export default { styleOverrides } as Components['MuiTabs'];
