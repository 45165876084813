import { CacheProvider, EmotionCache } from '@emotion/react';
import '@glidejs/glide/dist/css/glide.core.css';
import CssBaseline from '@mui/material/CssBaseline';
import { createGenerateClassName, StylesProvider } from '@mui/styles';
import AppContent from 'features/core/AppContent';
import Head from 'features/core/Head';
import WithTheme from 'features/core/theme/WithTheme';
import App, { AppProps } from 'next/app';
import { useEffect } from 'react';
import { getWindow } from 'utils/browserUtils';
import createEmotionCache from 'utils/createEmotionCache';
import { init as errorTrackerInit } from 'utils/ErrorTracker';
import { getGTMId } from 'utils/settings';
import '../styles/globals.css';
import {initializeAnalytics} from "../analytics_setup";

errorTrackerInit();

const clientSideEmotionCache = createEmotionCache();
interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  renderedFrom: string;
}

const MyApp = (props: MyAppProps) => {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    pageProps,
    renderedFrom
  } = props;
  const generateClassName = createGenerateClassName({ productionPrefix: 'kb' });

  const clientHost = getWindow()?.location.host;

  const isProd =
    renderedFrom === 'khatabook.com' || clientHost === 'khatabook.com';

  useEffect(() => {
    const gtmId = getGTMId();
    initializeAnalytics(false);
    if (gtmId) {
      (async function () {
        const TagManager = (await import('react-gtm-module')).default;

        TagManager.initialize({ gtmId: getGTMId() });
      })();
    }
  }, []);
  if (typeof window == 'undefined') {
    return <div />;
  }
  return (
    <CacheProvider value={emotionCache}>
      <Head />
      <StylesProvider generateClassName={generateClassName}>
        <WithTheme>
          <AppContent>
            <CssBaseline />

            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Component isProd={isProd} {...pageProps} />
          </AppContent>
        </WithTheme>
      </StylesProvider>
    </CacheProvider>
  );
};

MyApp.getInitialProps = async appContext => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return {
    ...appProps,
    renderedFrom: appContext.ctx.req?.headers['rendered-from']
  };
};

export default MyApp;
