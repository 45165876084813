import * as Sentry from '@sentry/react';
import { getWindow } from './browserUtils';
import {
  getSentryDSN,
  getSentryEnvironment
} from './settings';

export const init = (): void => {
  if (!getWindow()) return;

  Sentry.init({
    dsn: getSentryDSN(),
    environment: getSentryEnvironment(),
    enabled: !!getSentryDSN()
  });
};

export default Sentry;
