export class RudderstackAnalytics {
  private readonly isEnabled: boolean = false;
  private readonly key: string | undefined;
  private readonly dataPlaneUrl: string | undefined;
  private readonly pendingEvent: Array<{event: string, data: any}>

  constructor() {
    this.isEnabled = process.env.NEXT_PUBLIC_IS_RUDDERSTACK_ENABLED === '1';
    this.key = process.env.NEXT_PUBLIC_RUDDERSTACK_KEY;
    this.dataPlaneUrl = process.env.NEXT_PUBLIC_RUDDERSTACK_DATA_PLANE_URL;
    this.pendingEvent = []
  }

  private hasLoaded(): boolean {
    return !!window.rudderanalytics;
  }

  initialize() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const ref = this;
    console.log('Initializing rudderstack for analytics');
    if (!this.isEnabled) {
      console.log('Rudderstack not enabled, skipping initialisation');
      return;
    }
    if (!this.key || !this.dataPlaneUrl) {
      console.error(
        'Either key or dataplane URL is missing for rudderstack, not initialising'
      );
      return;
    }

    if (this.hasLoaded()) {
      window.console &&
        console.warn &&
        console.warn('Rudder analytics already loaded. returning.');
      return;
    }
    const e = (window.rudderanalytics = window.rudderanalytics || []);
    e.methods = [
      'load',
      'page',
      'track',
      'identify',
      'alias',
      'group',
      'ready',
      'reset',
      'getAnonymousId',
      'setAnonymousId',
      'getUserId',
      'getUserTraits',
      'getGroupId',
      'getGroupTraits'
    ];
    e.factory = function (t: any) {
      return function (...args: any) {
        const r = Array.prototype.slice.call(args);
        return r.unshift(t), e.push(r), e;
      };
    };
    for (let t = 0; t < e.methods.length; t++) {
      const r = e.methods[t];
      e[r] = e.factory(r);
    }
    e.loadJS = function (e: any, t: any) {
      const r = document.createElement('script');
      r.type = 'text/javascript';
      r.async = !0;
      r.src = 'https://cdn.rudderlabs.com/v1.1/rudder-analytics.min.js';
      r.addEventListener('load',ref.onLoadLibrary );

      const a = document.getElementsByTagName('script')[0];
      a.parentNode?.insertBefore(r, a);
    };
    e.loadJS();
    e.load(this.key, this.dataPlaneUrl);
    e.page();
  }

  onLoadLibrary=()=> {
    this.emitPendingEvent()
  }

  identifyUser(userId: string) {
    if (!this.isEnabled || !this.hasLoaded()) {
      return;
    }
    window.rudderanalytics.identify(userId);
  }

  emitPendingEvent = () => {
    if(this.pendingEvent?.length > 0){
      for(let i=this.pendingEvent.length-1;i>=0;i--){
        const item = this.pendingEvent[i];
        window.rudderanalytics.track(item.event, item.data)
        this.pendingEvent.splice(i,1)
      }
    }
  }

  trackEvent(event: string, data: any) {
    if (!this.isEnabled) {
      return;
    }
    if(!this.hasLoaded()){
      this.pendingEvent.push({
        event,
        data
      })
      return;
    }
    window.rudderanalytics.track(event, data);
  }
}
