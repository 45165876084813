import { ANDROID_APP_URL, IOS_APP_URL } from 'constants/appInfo';
import { KeyValue } from 'types/general';

const getWindow = (): Window | null =>
  typeof window === 'undefined' ? null : window;

const paramsToObject = (entries: URLSearchParams) => {
  const result: KeyValue = {};
  for (const [key, value] of entries) {
    // each 'entry' is a [key, value] tupple
    result[key] = value;
  }
  return result;
};

const parseQueryParams = (queryString: string) => {
  const params = new URLSearchParams(queryString);
  return params;
};

// eslint-disable-next-line import/prefer-default-export
export { getWindow, paramsToObject, parseQueryParams };

export const getCookieValue = (name: string) => {
  const env =
    process.env.NEXT_PUBLIC_REACT_APP_NODE_ENV ||
    process.env.REACT_APP_NODE_ENV;
  const cookieMatcher =
    env === 'production'
      ? '(^|;)\\s*' + name + '\\s*=\\s*([^;]+)'
      : '(^|;)\\s*' + 'dev-' + name + '\\s*=\\s*([^;]+)';
  return document.cookie.match(cookieMatcher)?.pop() || '';
};

export function checkForMobileDevice(): boolean {
  const _window = getWindow();
  if (!_window) return false;

  let isMobile = false;
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      _window.navigator.userAgent
    )
  ) {
    isMobile = true;
  }
  return isMobile;
}

export function getPlayStoreOrAppstoreKey() {
  const _window = getWindow();
  const userAgent = _window.navigator.userAgent;

  if (/android/i.test(userAgent)) {
    return ANDROID_APP_URL;
  } else if (/iPad|iPhone|iPod/.test(userAgent)) {
    return IOS_APP_URL;
  } else {
    return 'Unknown';
  }
}
