import { Components } from '@mui/material/styles';

import tab from './tab';
import tabs from './tabs';
import link from './link';
import list from './list';
import button from './button';
import toolbar from './toolbar';
import typography from './typography';

const components: Components = {
  MuiTab: tab,
  MuiTabs: tabs,
  MuiList: list,
  MuiLink: link,
  MuiButton: button,
  MuiToolbar: toolbar,
  MuiTypography: typography
};

export default components;
