import { PaletteOptions } from '@mui/material';

const palette: PaletteOptions = {
  primary: {
    main: '#C82128',
    light: '#FCF4F4',
    contrastText: '#FFFFFF'
  },
  secondary: {
    main: '#FEC855'
  },
  info: {
    main: '#20386E'
  },
  grey: {
    300: '#E0E0E0',
    700: '#D5D5D5',
    900: '#212121',
    A100: '#CBCDCD',
    A200: '#00000012',
    A400: '#000',
    A700: '#555959'
  },
  background: {
    paper: '#FFFFFF',
    default: '#FFFFFF'
  },
  warning: {
    main: '#D34D53'
  }
};

export default palette;
