import { Components, CSSInterpolation } from '@mui/material/styles';

const styleOverrides: CSSInterpolation = {
  root: ({ ownerState }) => ({
    ...(ownerState?.className?.includes('menu') && {
      fontFamily: 'Source Sans Pro',
      fontWeight: 400,
      fontSize: '1.25rem',
      lineHeight: 1.25
    }),
    ...(ownerState?.className?.includes('maxLines') && {
      display: '-webkit-box',
      overflow: 'hidden',
      // lineClamp: props.maxLines ? props.maxLines : null,
      WebkitBoxOrient: 'vertical'
    }),
    ...(ownerState?.className?.includes('breakAll') && {
      wordBreak: 'break-all'
    }),
    ...(ownerState?.className?.includes('lowOpacity') && {
      opacity: 0.8
    })
  })
};

export default { styleOverrides } as Components['MuiTypography'];
