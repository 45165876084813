import { Theme, createTheme } from '@mui/material/styles';

import components from './components';
import foundations from './foundations';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    link: true;
  }
}

export const defaultTheme = { ...foundations, components };
const buildTheme = (): Theme => createTheme(defaultTheme);

const theme = buildTheme();

export default theme;
