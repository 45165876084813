import { Components, CSSInterpolation } from '@mui/material/styles';

const styleOverrides: CSSInterpolation = {
  root: ({ ownerState }) => ({
    padding: 0,
    ...(ownerState.className === 'desktopNavList' && {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      gap: '0 2rem',
      marginLeft: '2rem',
      '& > a': {
        whiteSpace: 'nowrap'
      }
    }),
    ...(ownerState.className === 'mobileNavList' && {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '65vw',
      maxWidth: '16rem',
      '& > *': {
        width: '100%',
        textAlign: 'center'
      }
    })
  })
};
export default { styleOverrides } as Components['MuiList'];
