import { Components, CSSInterpolation } from '@mui/material/styles';

const styleOverrides: CSSInterpolation = {
  root: ({ ownerState }) => ({
    ...(ownerState.component === 'nav' && {
      display: 'flex',
      justifyContent: 'space-between',
      height: '6.25rem'
    })
  })
};

export default { styleOverrides } as Components['MuiToolbar'];
