import { useWindowHeight } from '@react-hook/window-size';
import CenterContentBox from 'components/CenterContentBox';
import { useCallback } from 'react';
import { getWindow } from 'utils/browserUtils';
import ErrorTracker from 'utils/ErrorTracker';
import ApiError from './ApiError';

const ErrorManager: React.FC = ({ children }) => {
  const height = useWindowHeight();

  const onClickRetry = useCallback(() => {
    getWindow().location.reload();
  }, []);

  return (
    <ErrorTracker.ErrorBoundary
      fallback={
        <CenterContentBox minHeight={height}>
          <ApiError message="Something went wrong!!" onRetry={onClickRetry} />
        </CenterContentBox>
      }
    >
      {children}
    </ErrorTracker.ErrorBoundary>
  );
};

export default ErrorManager;
